import { CloseCross } from '../../icons'
import React, { PropsWithChildren } from 'react'
import { useBreakPoint } from '../../hooks'
import * as DialogPrimitive from '@radix-ui/react-dialog'
import { ButtonPlatform } from '../button-platform'

export type ModalCenteredProps = PropsWithChildren & {
  title: string
  isOpen: boolean
  onOpenChange: (isOpen: boolean) => void
  mainButton?: {
    text: string
    onClick: () => void
  }
  icon?: React.ReactNode
}

export function ModalCentered({
  title,
  isOpen,
  onOpenChange,
  mainButton,
  children,
  icon,
}: ModalCenteredProps) {
  const isBp1 = useBreakPoint('bp1')

  return (
    <DialogPrimitive.Root open={isOpen} onOpenChange={onOpenChange}>
      <DialogPrimitive.Trigger asChild></DialogPrimitive.Trigger>
      <DialogPrimitive.Portal>
        <DialogPrimitive.Close asChild>
          <DialogPrimitive.Overlay
            style={{
              backgroundColor: '#00000040',
              position: 'fixed',
              inset: '0',
              animation: 'overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1)',
              zIndex: 50,
            }}
          />
        </DialogPrimitive.Close>
        <DialogPrimitive.Content
          style={{
            backgroundColor: 'white',
            borderRadius: isBp1 ? '0px' : '12px',
            boxShadow:
              'hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px',
            position: 'fixed',
            top: isBp1 ? '0' : '50%',
            left: isBp1 ? '0' : '50%',
            transform: isBp1 ? '' : 'translate(-50%, -50%)',
            width: isBp1 ? '100%' : '90vw',
            height: isBp1 ? '100%' : 'fit-content',
            maxWidth: isBp1 ? '100%' : '450px',
            maxHeight: isBp1 ? '100%' : '85vh',
            padding: '25px',
            animation: 'contentShow 150ms cubic-bezier(0.16, 1, 0.3, 1)',
            zIndex: 51,
          }}>
          <div
            className={
              'flex flex-col gap-4 bp1:h-full bp1:justify-between leading-5 my-6'
            }>
            <div className={'flex flex-col gap-3'}>
              <div
                className={'fixed top-0 right-0 cursor-pointer p-5'}
                onClick={async () => {
                  onOpenChange(false)
                }}>
                <CloseCross className="fill-black stroke-black" />
              </div>

              {icon}

              <p
                className={
                  'text-xl font-medium text-[#0f1728] leading-[27px] text-center'
                }>
                {title}
              </p>

              {children}
            </div>

            {mainButton && (
              <div className={'grid justify-items-center'}>
                <ButtonPlatform
                  full={false}
                  onClick={() => {
                    mainButton?.onClick()
                  }}>
                  <p
                    className={
                      'text-xs font-semibold leading-none tracking-wide'
                    }>
                    {mainButton.text}
                  </p>
                </ButtonPlatform>
              </div>
            )}
          </div>
        </DialogPrimitive.Content>
      </DialogPrimitive.Portal>
    </DialogPrimitive.Root>
  )
}
