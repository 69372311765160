import { useEffect, useState } from 'react'
import { mediaBreakPoints } from '../config'

type queryType = keyof typeof mediaBreakPoints

export function useBreakPoint(query: queryType) {
  const [matches, setMatches] = useState(false)

  useEffect(() => {
    if (typeof window === 'undefined') return
    const media = window.matchMedia(mediaBreakPoints[query])

    if (media.matches !== matches) {
      setMatches(media.matches)
    }
    const listener = () => {
      setMatches(media.matches)
    }
    if (typeof media.addEventListener === 'function') {
      media?.addEventListener('change', listener)
      return () => media?.removeEventListener('change', listener)
    } else if (typeof media.addListener === 'function') {
      media?.addListener(listener)
      return () => media?.removeListener(listener)
    }
  }, [matches, query])

  return matches
}
