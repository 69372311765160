import { useSession } from '../ui'
import { useCallback } from 'react'
import { UserPermissionsType } from '@repo/types/common'

export function usePermissions() {
  const { session } = useSession()

  const hasPermission = useCallback(
    (permission: UserPermissionsType) =>
      (session?.user?.permissions || []).includes(permission),
    [session]
  )

  const hasAnyPermission = useCallback(
    (permissions: UserPermissionsType[]) =>
      permissions.some((permission) => hasPermission(permission)),
    [hasPermission]
  )

  return {
    session,
    hasPermission,
    hasAnyPermission,
  }
}
