'use client'
import { LoginFooter } from '@/app/account/components/components/login-footer'
import { NinjaWhite } from '@repo/ui/components/platforms/ninja-white'
import Canvas from '@/lib/gradient/Canvas'
import { NBackground, Ninja } from '@repo/ui'

export default function AccountContainer({ children }) {
  return (
    <div>
      <NinjaWhite
        className="invisible fixed left-6 top-6 md:visible"
        style={{
          width: 122,
          height: 48,
        }}
      />
      <div className={'invisible md:visible'}>
        <Canvas />
      </div>
      <div className="mx-auto flex min-h-screen max-w-screen-xl flex-col">
        <NBackground className="invisible fixed bottom-0 left-1/2 -z-10 -translate-x-1/2 translate-y-1/3 md:visible " />

        <header className="flex justify-center pb-10 pt-20 md:justify-start md:p-8">
          <Ninja
            className="block md:hidden"
            style={{
              width: 170,
              height: 77,
            }}
          />
        </header>
        <main className="flex-grow content-center">
          <div className={'mx-auto justify-end md:w-1/2 lg:w-1/3'}>
            <div
              className={
                'flex flex-col gap-5 px-6 py-8 md:rounded-2xl md:bg-white'
              }>
              <MobileBackgroundGradient />
              {children}
            </div>
          </div>
        </main>

        <div className="hidden py-6 md:block">
          <LoginFooter />
        </div>
      </div>
    </div>
  )
}

function MobileBackgroundGradient() {
  return (
    <div className="absolute inset-0 -z-10 overflow-hidden md:hidden">
      <div className="absolute -left-1/3 -top-20 h-1/6 w-2/3 rounded-full bg-platform-black-950 blur-[135px]"></div>
      <div className="absolute -top-20 left-1/3 h-1/6 w-2/3 rounded-full bg-platform-blue-950 blur-[135px]"></div>
      <div className="absolute -top-20 left-2/3 h-1/6 w-2/3 rounded-full bg-platform-red-ninja blur-[135px]"></div>
    </div>
  )
}
