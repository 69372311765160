import { ModalCentered } from '@repo/ui/components/modal/modal-centered'
import React from 'react'
import { useEliteContentStore } from '@repo/ui/components/badges/elite-content-store'
import { useRouter } from 'next/navigation'

type EliteModalProps = {}
export default function EliteModal({}: EliteModalProps) {
  const { setOpen, isOpen } = useEliteContentStore()
  const router = useRouter()

  return (
    <ModalCentered
      isOpen={isOpen}
      onOpenChange={setOpen}
      icon={
        <img
          src="/images/elite/elite-short.png"
          alt="logo"
          className="mx-auto"
          width="129px"
          height="118px"
        />
      }
      mainButton={{
        text: 'HAZTE ÉLITE',
        onClick: () => {
          setOpen(false)
          router.push('/perfil/elite')
        },
      }}
      title={'Contenido Exclusivo Plan Élite'}>
      <p
        className={
          'text-center text-base font-normal leading-normal text-[#475466]'
        }>
        Ten acceso a contenido exclusivo, tutorías en grupos reducidos,
        profesores a tu disposición, y a una comunidad de élite... ¡Sácale
        ventaja al resto de opositores!
      </p>
    </ModalCentered>
  )
}
