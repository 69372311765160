'use client'
import { styled } from '../config'

function Svg(props) {
  return (
    <svg
      width="18"
      height="10"
      viewBox="0 0 18 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.87154 1.00015H4.69231C3.71305 1.00015 2.77389 1.38916 2.08145 2.0816C1.38901 2.77404 1 3.7132 1 4.69246C1 5.67172 1.38901 6.61087 2.08145 7.30331C2.77389 7.99576 3.71305 8.38477 4.69231 8.38477H6.82346M11.1765 8.38477H13.3077C14.287 8.38477 15.2261 7.99576 15.9185 7.30331C16.611 6.61087 17 5.67172 17 4.69246C17 3.7132 16.611 2.77404 15.9185 2.0816C15.2261 1.38916 14.287 1.00015 13.3077 1.00015H11.1285M5.65654 4.69246H12.4204"
        stroke="black"
        strokeWidth="1.84615"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const LinkUrl = styled(Svg, {})
