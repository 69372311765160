import { forwardRef, SVGProps } from 'react'

export const Transfer = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>(
  function TrashBackgroundImpl(props, forwardRef: any) {
    return (
      <svg
        width="12"
        height="13"
        viewBox="0 0 12 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}>
        <path
          id="Vector"
          d="M11.7317 3.80791L11.7242 3.81502V3.81756L9.16025 6.24771C8.8347 6.55628 8.30548 6.55628 7.97993 6.24771C7.65511 5.93984 7.65511 5.44039 7.97993 5.13252L9.08886 4.08145L9.13263 4.03997H9.07232H0.857143C0.395082 4.03997 0.0240385 3.68648 0.0240385 3.25159C0.0240385 2.81669 0.395082 2.4632 0.857143 2.4632H9.07232H9.13263L9.08886 2.42172L7.97993 1.37065C7.65511 1.06278 7.65511 0.563332 7.97993 0.255461C8.30548 -0.0531023 8.8347 -0.0531023 9.16025 0.255461L11.7317 2.69272C12.0565 3.00059 12.0565 3.50004 11.7317 3.80791ZM4.01739 12.7445C3.70529 13.0404 3.20598 13.0526 2.87842 12.7812L2.83975 12.7445L0.268322 10.3073C-0.0564966 9.99941 -0.0564966 9.49996 0.268322 9.19209L2.83975 6.75483C3.1653 6.44626 3.69452 6.44626 4.02007 6.75483C4.34489 7.0627 4.34489 7.56214 4.02007 7.87002L2.91114 8.92109L2.86737 8.96257H2.92768H11.1429C11.6049 8.96257 11.976 9.31606 11.976 9.75095C11.976 10.1858 11.6049 10.5393 11.1429 10.5393H2.92768H2.86737L2.91114 10.5808L4.01739 11.6293C4.34221 11.9372 4.34221 12.4367 4.01739 12.7445Z"
          fill="black"
          stroke="#D2DCED"
          strokeWidth="0.0480769"
        />
      </svg>
    )
  }
)
