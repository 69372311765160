'use client'
import { styled } from '../config'

function Svg(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      {...props}>
      <path
        d="M17 9C17 4.58333 13.4167 1 9 1C4.58333 1 1 4.58333 1 9C1 13.4167 4.58333 17 9 17C13.4167 17 17 13.4167 17 9Z"
        stroke="currentColor"
        strokeWidth="1.33333"
        stroke-miterlimit="10"
      />
      <path
        d="M8.76196 5.25221L9.00113 10.3335L9.23988 5.25221C9.24136 5.21973 9.23619 5.18728 9.2247 5.15686C9.21321 5.12644 9.19564 5.09868 9.17306 5.07529C9.15048 5.05189 9.12337 5.03334 9.09338 5.02077C9.06339 5.00821 9.03115 5.00189 8.99863 5.00221C8.9665 5.00251 8.93476 5.00928 8.90531 5.02213C8.87586 5.03497 8.84929 5.05361 8.8272 5.07694C8.80511 5.10028 8.78795 5.12782 8.77674 5.15794C8.76553 5.18805 8.76051 5.22011 8.76196 5.25221Z"
        stroke="currentColor"
        strokeWidth="1.33333"
        strokeLinecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.0013 13.6628C8.83648 13.6628 8.67537 13.6139 8.53833 13.5223C8.40129 13.4308 8.29448 13.3006 8.2314 13.1483C8.16833 12.9961 8.15183 12.8285 8.18398 12.6669C8.21614 12.5052 8.2955 12.3567 8.41205 12.2402C8.52859 12.1236 8.67708 12.0443 8.83873 12.0121C9.00038 11.98 9.16793 11.9965 9.3202 12.0595C9.47248 12.1226 9.60263 12.2294 9.69419 12.3665C9.78576 12.5035 9.83464 12.6646 9.83464 12.8294C9.83464 13.0504 9.74684 13.2624 9.59056 13.4187C9.43428 13.575 9.22232 13.6628 9.0013 13.6628Z"
        stroke="currentColor"
      />
    </svg>
  )
}

export const Exclamation = styled(Svg)
