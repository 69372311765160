'use client'
import { UserLoginConfig } from '@/components/account/user-login-config'
import { API_URL } from '@/config/constants'
import { useGoogleTagManager } from '@/lib/analytics/google-tag-manager/use-google-tag-manager'
import { TrackingComponent } from '@/screens/common/tracking-component'
import useCheckSubscription from '@/services/api/auth/use-check-subscription'
import { useSession } from 'next-auth/react'
import dynamic from 'next/dynamic'
import React, { PropsWithChildren, Suspense, useEffect } from 'react'
import { install } from 'resize-observer'
import { Toaster } from 'sonner'
import { SWRConfig } from 'swr'
import { swrConfig, useFetcher } from '@repo/utils'
import { SessionResolver } from '@repo/users'
import EliteModal from '@/components/elite-modal'
import LostConnectionModal from '@/components/lost-connection'
// We use the polyfill to support ResizeObserver in older browsers

const UserImageWatcher = () => {
  const { data: session, status, update } = useSession()

  useEffect(() => {
    if (status === 'authenticated' && !session?.user?.image?.url) {
      update({
        ...session,
        newData: {
          image: true,
        },
      })
    }
  }, [status, update, session])

  return null
}

const WatchChatFlowHubspot = dynamic(
  async () => (await import('@/lib/hubspot-script')).WatchChatFlowHubspot,
  { ssr: false }
)

if (typeof window !== 'undefined') {
  // polyfill for resize-observer
  install()
}

const selectorSetUrl = (state) => state.setUrl

function MainBodyImpl({ children }: PropsWithChildren) {
  const setUrl = useFetcher(selectorSetUrl)

  useGoogleTagManager()
  useCheckSubscription()

  useEffect(() => {
    setUrl(`${API_URL}/graphql`)
  }, [setUrl])

  return (
    <main>
      <WatchChatFlowHubspot />
      <UserImageWatcher />
      <SWRConfig value={swrConfig}>
        <UserLoginConfig>
          <TrackingComponent>{children}</TrackingComponent>
        </UserLoginConfig>
      </SWRConfig>
      <Toaster richColors position="top-center" />
      <EliteModal />
      <LostConnectionModal />
    </main>
  )
}

export function MainBody({ children }: PropsWithChildren) {
  const { data: session, status } = useSession()

  return (
    <Suspense>
      <SessionResolver session={session} isLoading={status === 'loading'}>
        <MainBodyImpl>{children}</MainBodyImpl>
      </SessionResolver>
    </Suspense>
  )
}
