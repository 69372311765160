'use client'
import { createContext, useContext } from 'react'
import useSWR from 'swr'
import { SwrGetState } from '@repo/types/admin/swrResponse'
import { getCalendarStreak } from '@repo/streak/services/get-calendar-streak'
import { StreakCalendar } from '@repo/streak/types/streak-types'

const StreakContext = createContext<SwrGetState<StreakCalendar | null>>({
  data: null,
  mutate: () => {},
  isLoading: false,
  error: null,
  isValidating: false,
})

export const useStreak = () => useContext(StreakContext)

export function StreakProvider({
  userId,
  children,
}: {
  userId: number
  children: React.ReactNode
}) {
  const { data, mutate, isLoading, error, isValidating } =
    useSWR<StreakCalendar | null>(
      `streak-${userId}`,
      async () => await getCalendarStreak(userId, 5),
      {
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
        refreshInterval: 600000, //10 minutes
      }
    )

  return (
    <StreakContext.Provider
      value={{
        data: data || null,
        mutate,
        isLoading,
        error,
        isValidating,
      }}>
      {children}
    </StreakContext.Provider>
  )
}
