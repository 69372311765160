import { RawEventStatus } from '@repo/database'

export enum TrackEvent {
  USAGE = 'USAGE',
  PAGE_VIEW = 'PAGE_VIEW',
  COURSE_CLASS_VIEW = 'COURSE_CLASS_VIEW',
  CLOSE_SESSION = 'CLOSE_SESSION',
  LOGIN = 'LOGIN',
  SESSION_TIME = 'SESSION_TIME',
  ANSWER_QUESTION = 'ANSWER_QUESTION',
  ACTIVE_USERS = 'ACTIVE_USERS',
  CLICK_ON = 'CLICK_ON',
  NORTH_STAR = 'NORTH_STAR',
  VIEWING_VIDEO = 'VIEWING_VIDEO',
  HINT_VIEW = 'HINT_VIEW',
  HINT_CLOSE = 'HINT_CLOSE',
  STREAK_FLAME = 'STREAK_FLAME',
  STREAK_HOME = 'STREAK_HOME',
}

export enum TrackEventValue {
  STATS_HOW_IT_WORKS = 'STATS_HOW_IT_WORKS',
  STATS_BUTTON_SEE_OLD_VERSION = 'STATS_BUTTON_SEE_OLD_VERSION',
  STATS_BUTTON_SEE_NEW_VERSION = 'STATS_BUTTON_SEE_NEW_VERSION',
  STATS_COURSES_TABLE_ORDER_BY = 'STATS_COURSES_TABLE_ORDER_BY',
  STATS_TEST_TABLE_ORDER_BY = 'STATS_TEST_TABLE_ORDER_BY',
  LOGIN_DIRECT = 'LOGIN_DIRECT',
  LOGIN_TOTP = 'LOGIN_TOTP',
  LOGIN_PHONE_VERIFICATION = 'LOGIN_PHONE_VERIFICATION',
  LOGIN_TWO_FACTOR = 'LOGIN_TWO_FACTOR',
}

export type RawEvents = {
  id: string
  userId: number
  event: string
  value: string
  data?: {}
  platformId: number
  examId: number
  processStatus: RawEventStatus
  createdAt: Date
}
export type RawEventsGroupedDB = {
  user_id: number
  platform_id: number
  exam_id: number
  event: string
  value: string
  data: {}
  created_at: string
  test_ab: RawEventsTestAbGroupedDB[]
}

export type RawEventsTestAbGroupedDB = {
  e: number
  c: number
  v: number
}

export type EventsKpi = {
  items: RawEvents[]
  total: number
  event: string
  value: string
  platformId: number
  examId: number
  userId?: number
  createdAt: Date
  testAB?: number
  testABConfig?: number
  testABVariant?: number
}
export type UserActivity = {
  user_id: number
  platform_id: number
  exam_id: number
  first_date: Date
  activity_dates: Date[]
  test_ab: RawEventsTestAbGroupedDB[]
}

export type SessionMinutes = {
  date: Date
  platformId: number
  examId: number
  minutes: number
  test_ab: RawEventsTestAbGroupedDB[]
}
