import { PropsWithChildren, useEffect } from 'react'
import { useSession } from 'next-auth/react'
import { useFetcher } from '@repo/utils'
import { usePathname } from 'next/navigation'

export function UserLoginConfig({ children }: PropsWithChildren) {
  const { data: session } = useSession()
  const pathname = usePathname()
  const setToken = useFetcher((state) => state.setAuthToken)

  useEffect(() => {
    if (session?.user?.error === 'invalid_token') {
      // Sign out here
      setToken(null)
    }
  }, [session?.user?.error, pathname, setToken])

  return <>{children}</>
}
