import { PropsWithChildren } from 'react'
import { UserPermissionsType } from '@repo/types/common'
import { usePermissions } from '../hooks'

export type AccessViewProps = PropsWithChildren & {
  permission: UserPermissionsType | UserPermissionsType[]
}

export function AccessView({ permission, children }: AccessViewProps) {
  const { hasPermission, hasAnyPermission } = usePermissions()

  if (Array.isArray(permission)) {
    return <>{hasAnyPermission(permission) && <>{children}</>}</>
  }

  return <>{hasPermission(permission) && <>{children}</>}</>
}
