import { create } from 'zustand'

type EliteContentStore = {
  isOpen: boolean
  setOpen: (isOpen: boolean) => void
}

export const useEliteContentStore = create<EliteContentStore>((set, get) => ({
  isOpen: false,
  setOpen: (isOpen: boolean) => {
    set({
      isOpen,
    })
  },
}))
