'use client'

import React from 'react'
import { MAX_TIME_TOKEN_VERIFICATION } from '@/config/constants'
import { SessionProvider } from 'next-auth/react'

export function NextSessionProvider({ children, session }) {
  return (
    <SessionProvider
      session={session}
      refetchInterval={MAX_TIME_TOKEN_VERIFICATION}
      refetchOnWindowFocus={true}>
      {children}
    </SessionProvider>
  )
}
