'use client'
import { styled } from '../../config'
import * as React from 'react'

function Svg({ active, ...rest }) {
  return (
    <svg
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}>
      <g clipPath="url(#clip0_4449_2864)">
        <path
          d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
          fill="#E30613"
        />
        <path
          d="M52.8488 28.0008C52.8488 14.2765 41.7231 3.15076 27.9988 3.15076C14.2745 3.15076 3.1488 14.2765 3.1488 28.0008C3.1488 41.725 14.2745 52.8508 27.9988 52.8508C41.7231 52.8508 52.8488 41.725 52.8488 28.0008Z"
          stroke="url(#paint0_linear_4449_2864)"
          strokeWidth="0.7"
        />
        <path
          d="M44 38H38.2437C38.2437 33.672 36.9477 30.3971 34.2928 28.0359C30.0605 24.2753 23.3868 23.7079 19.7321 23.7079V38H14V18.4325L16.553 18.1488C17.1063 18.0862 30.143 16.7061 38.1126 23.7704C42.0198 27.228 44 32.0177 44 38Z"
          fill="white"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_4449_2864"
          x1="27.9988"
          y1="2.80076"
          x2="27.9988"
          y2="53.2008"
          gradientUnits="userSpaceOnUse">
          <stop stopOpacity="0.1" />
          <stop offset="1" stopOpacity="0.05" />
        </linearGradient>
        <clipPath id="clip0_4449_2864">
          <rect width="56" height="56" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export const VideosCortos = styled(Svg)
