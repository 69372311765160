import { create } from 'zustand'

type LostConnectionStore = {
  isOpen: boolean
  setOpen: (isOpen: boolean) => void
}

export const useLostConnectionStore = create<LostConnectionStore>(
  (set, get) => ({
    isOpen: false,
    setOpen: (isOpen: boolean) => {
      set({
        isOpen,
      })
    },
  })
)
