import * as React from 'react'
import { styled } from '../config'

const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={1234}
    height={833}
    fill="none"
    {...props}>
    <path
      fill="url(#a)"
      fillRule="evenodd"
      d="M841.627 409.409C953.692 510.013 1008.23 648.578 1008.23 833H1234c0-1.335 0-2.668-.01-4-.87-247.364-82.46-445.396-242.476-588.455C662.919-53.372 123.114 3.481 100.315 6.055L0 17.285V833h225.825V226.156c150.531-.701 435.633 21.642 615.802 183.253ZM221.825 829V222.175l3.982-.019c75.448-.351 184.684 5.069 297.694 30.86 112.981 25.783 230.021 71.99 320.797 153.415l.001.001C956.578 507.229 1011.44 645.704 1012.22 829h217.77c-.87-246.416-82.11-443.292-241.142-585.473C825.424 97.349 609.261 38.245 430.748 16.086 252.301-6.066 111.992 8.762 100.763 10.03L4 20.863V829h217.825Z"
      clipRule="evenodd"
    />
    <defs>
      <linearGradient
        id="a"
        x1={101.5}
        x2={266.027}
        y1={0}
        y2={859.217}
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#B20F1C" />
        <stop offset={0.5} stopColor="#5A2024" />
        <stop offset={1} />
      </linearGradient>
    </defs>
  </svg>
)
export const NBackground = styled(SvgComponent)
