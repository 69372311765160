import { signIn, SignInResponse } from 'next-auth/react'
import { useFetcher } from '@repo/utils'
import { validateTokenQuery } from '@/services/api/auth/validate-token.query'
import { ValidateTokenOutput } from '@repo/types/web'

export const validateToken = async (
  token: string
): Promise<SignInResponse | Promise<any>> => {
  if (!token || token === 'null') {
    return { url: '/', status: 1, error: null, ok: false }
  }

  return signIn('wp-token', { token, redirect: false })
}

export const checkToken = async (
  token: string
): Promise<ValidateTokenOutput> => {
  const fetcher = useFetcher.getState().fetcher

  const data = await fetcher({
    query: validateTokenQuery,
    variables: { token },
    errorHandler: (error) => {
      console.log(error)
    },
  })

  return data.validateToken
}
