import React, { useRef, useEffect } from 'react'
import styles from './canvas.module.css'
import { StripeGradient } from './StripeGradient'

const Canvas = (props) => {
  const canvasRef2 = useRef(null)
  const gradient = new StripeGradient()

  useEffect(() => {
    gradient.initGradient('#gradient-canvas')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <canvas
      className={styles.canvas}
      id="gradient-canvas"
      ref={canvasRef2}
      {...props}
      data-transition-in
    />
  )
}

export default Canvas
